<template>
  <div class="group">
    <div class="group-head">
      <div class="group-head-l">
        <el-dropdown @command="handleCommand">
          <el-button type="primary">
            创建拼团<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-tooltip
              class="item"
              effect="dark"
              content="以优惠价吸引客户开团，依托社交关系链裂变带来流量，帮助店铺提升购买转化。"
              placement="right-start"
            >
              <el-dropdown-item command="1">普通拼团</el-dropdown-item>
            </el-tooltip>

            <el-tooltip
              class="item"
              effect="dark"
              content="新老客户都可下单开团，仅限无购买记录的新客可以参团，快速裂变获取新客。"
              placement="right-start"
            >
              <el-dropdown-item command="2"> 老带新拼团</el-dropdown-item>
            </el-tooltip>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="group-head-r">
        <!-- <el-input
          placeholder="请输入内容"
          v-model="input3"
          class="input-with-select"
        >
          <el-select
            v-model="select"
            style="width:100px;"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="餐厅名" value="1"></el-option>
            <el-option label="订单号" value="2"></el-option>
            <el-option label="用户电话" value="3"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input> -->
      </div>
    </div>

    <div class="group-box">
      <el-table
        :data="tableData"
        :border="$table('border')"
        :stripe="$table('stripe')"
        :header-cell-style="{ background: $table('bgcolor') }"
        style="width: 100%"
      >
        <el-table-column prop="id" label="序号" :width="$table('width')">
          <template slot-scope="scope">
              <span v-if="pages < 2">
                {{ scope.$index + 1 }}
              </span>
              <span v-else>
                {{ (scope.$index + 1) + pages * 15 -15}}
              </span>
            </template>
        </el-table-column>
        <el-table-column prop="name" label="活动名称"> </el-table-column>
        <el-table-column prop="begin_time" label="活动时间"> </el-table-column>
        <!-- <el-table-column prop="name" label="适用门店"> </el-table-column> -->
        <el-table-column prop="status" label="活动状态">
          <template slot-scope="scope">
            {{ scope.row.status > 0 ? "正常" : "已结束" }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="open_count" label="开团数"></el-table-column> -->
        <el-table-column label="开团数">
          <template slot-scope="scope">
            <div class="tag-group" v-if="scope.row.open_count > 0" @click="handleShowGroup(scope.row.id)">
              <span class="tag-group__title" style="color: #409EFF;text-decoration: underline;cursor: pointer;">{{scope.row.open_count}}</span>
            </div>
            <div class="tag-group" v-else>
              <span class="tag-group__title">{{scope.row.open_count}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="success_count" label="成团数"></el-table-column>
        <!-- <el-table-column label="成团数">
          <template slot-scope="scope">
            <div class="tag-group" v-if="scope.row.success_count > 0" @click="handleShowGroup(scope.row.id)">
              <span class="tag-group__title">{{scope.row.success_count}}</span>
            </div>
            <div class="tag-group" v-else>
              <span class="tag-group__title">{{scope.row.success_count}}</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="open_order_count" label="参团订单数">
        </el-table-column>
        <el-table-column prop="success_order_count" label="成团订单数">
          <template slot="header">
            <el-popover
              placement="top-start"
              title="说明"
              width="200"
              trigger="hover"
              content="开团数：该活动成功支付开团的总团数。成团数：该活动拼团成功的总团数。参团订单数：该活动成功支付的订单总数。成团订单数：该活动成团（即订单状态为已成团）的订单总数"
            >
              <span slot="reference" style="cursor: pointer;"
                >成团订单数 <i class="el-icon-question"></i
              ></span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作">
          <template slot-scope="scope">
            <div class="btn-box">
              <el-button size="mini" @click="handleEdit(scope)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="$pageStatus(totals)"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[20, 30, 50, 80]"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :hide-on-single-page="total == true"
        :total="totals"
      ></el-pagination>
    </div>

    <!-- 添加活动 -->
    <el-dialog
      :title="type ? '编辑拼团' : '创建拼团'"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <el-alert title="基础信息" type="info" :closable="false"> </el-alert>

      <div class="alerts">
        <div class="alerts-list">
          <span>活动名称：</span>
          <div class="alerts-list-item">
            <el-input
              style="width:60%"
              v-model="form.name"
              placeholder="请输入内容"
            ></el-input>
          </div>
        </div>
        <div class="alerts-list">
          <span>开始时间：</span>
          <div class="alerts-list-item">
            <el-date-picker
              v-model="form.begin_time"
              type="date"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            >
            </el-date-picker>
            <span style="margin:0 10px;"> 至 </span>
            <el-date-picker
              v-model="form.end_time"
              type="date"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="alerts-list">
          <span>购买时间：</span>
          <div class="alerts-list-item">
            <el-date-picker
              v-model="form.buy_begin_time"
              type="date"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            >
            </el-date-picker>
            <span style="margin:0 10px;"> 至 </span>
            <el-date-picker
              v-model="form.buy_end_time"
              type="date"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="alerts-list">
          <span>团有效期：</span>
          <div class="alerts-list-item">
            <el-input-number
              v-model="form.day"
              :min="0"
              :max="9999"
              label="描述文字"
            ></el-input-number
            ><span style="margin:0 10px;"> 天 </span>
            <el-input-number
              v-model="form.time"
              :min="0"
              :max="9999"
              label="描述文字"
            ></el-input-number
            ><span style="margin:0 10px;"> 时 </span>
            <el-input-number
              v-model="form.minute"
              :min="0"
              :max="60"
              label="描述文字"
            ></el-input-number
            ><span style="margin:0 10px;"> 分 </span>
          </div>
        </div>

        <div class="alerts-list" v-if="0">
          <span>活动店铺：</span>
          <div class="alerts-list-item">
            <el-select
              v-model="form.shop"
              multiple
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="alerts-list">
          <span>参团限制：</span>
          <div class="alerts-list-item">
            <el-radio v-model="form.group_type" label="1"
              >所有客户均可参团</el-radio
            >
            <el-radio v-model="form.group_type" label="2"
              >仅限新客参团</el-radio
            >
          </div>
        </div>
        <div class="alerts-list">
          <span>活动状态：</span>
          <div class="alerts-list-item">
            <el-radio v-model="form.status" label="-1">关闭 </el-radio>
            <el-radio v-model="form.status" label="1">开启</el-radio>
          </div>
        </div>

        <div class="alerts-list">
          <span> 活动价格：</span>
          <div class="alerts-list-item">
            <span>成团人数：</span>
            <el-input-number
              v-model="form.people_count"
              :min="0"
              :max="9999"
              label="描述文字"
            ></el-input-number>
            <span>价格：</span>
            <el-input-number
              v-model="form.retail_price"
              :min="0"
              :max="99999"
              label="描述文字"
            ></el-input-number>
            <span>拼团价:</span>
            <el-input-number
              v-model="form.group_price"
              :min="0"
              :max="999999"
              label="描述文字"
            ></el-input-number>
          </div>
        </div>

        <div class="alerts-list">
          <span>活动商品：</span>
          <div class="alerts-list-item">
            <el-dropdown @command="handleCommand1">
              <el-button type="primary">
                请选择活动商品<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">选择商品</el-dropdown-item>
                <el-dropdown-item command="2">选择服务</el-dropdown-item>
                <el-dropdown-item command="3">选择卡项 </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div class="alerts-lists">
          <el-table
            :data="form.goods_info"
            :border="$table('border')"
            :stripe="$table('stripe')"
            :header-cell-style="{ background: $table('bgcolor') }"
            style="width: 100%"
          >
            <el-table-column prop="id" label="编号" :width="$table('width')">
              <template>{{ form.goods_id }}</template>
            </el-table-column>
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="address" label="操作">
              <template>
                <el-button
                  size="mini"
                  type="danger"
                  @click="form.goods_info = []"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <el-alert title="高级设置" type="info" :closable="false"> </el-alert>

      <div class="alerts">
        <div class="alerts-list">
          <div class="alerts-list-item">
            <span>凑团：</span>
            <el-switch v-model="form.is_coutuan"> </el-switch>
          </div>
        </div>
        <el-alert
          title="开启凑团后，对于未参团的买家，活动商品详情页会显示待成团的团列表，买家可以直接任选一个参团，提升成团率。"
          type="warning"
          :closable="false"
        >
        </el-alert>
      </div>

      <div class="alerts">
        <div class="alerts-list">
           活动详情：
        </div>
        <div class="alerts-list">
          <!-- <vue-ueditor-wrap
            v-model="form.detail"
            :config="config"
          /> -->
          <i-quill-editor :content="form.detail" @textChange="handleTextChange"></i-quill-editor>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save_pintuan">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加商品 -->
    <el-dialog title="选择商品" :visible.sync="dialogVisible2" width="80%">
      <el-tabs tab-position="top">
        <el-tab-pane
          :label="item.name"
          v-for="(item, index) in product_type"
          :key="index"
        >
          <el-table
            :data="item.list"
            :border="$table('border')"
            :stripe="$table('stripe')"
            :header-cell-style="{ background: $table('bgcolor') }"
            style="width: 100%"
          >
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="stock" label="库存">
              <template slot-scope="scope">
                {{ scope.row.stock ? scope.row.stock : "9999999" }}
              </template>
            </el-table-column>
            <el-table-column prop="stock" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="select_goods(scope.row)"
                  type="text"
                  size="small"
                  >选择商品</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <el-pagination
        v-if="$pageStatus(total)"
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="currentPage4"
        :page-sizes="[20, 30, 50, 80]"
        :page-size="20"
        layout="total,  prev, pager, next, jumper"
        :hide-on-single-page="total == true"
        :total="total"
      ></el-pagination>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible2 = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
    <el-dialog title="拼团列表" :visible.sync="dialogTableVisible">
      <el-table :data="tuanListData">
        <el-table-column label="推荐人">
          <template slot-scope="scope">
            <div class="tag-group" v-if="scope.row.p_user && scope.row.p_user.name">
              <span class="tag-group__title" :style="scope.row.p_user.staff_id > 0 ? 'color: #000000' : 'color: #CCCCCC'">{{scope.row.p_user.name}}</span>
              <!-- <el-tag effect="plain" size="small">{{scope.row.p_user.staff_id > 0 ? '员工' : '普通会员'}}</el-tag> -->
            </div>
            <div class="tag-group" v-else>
              <span class="tag-group__title">--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="参与会员">
          <template slot-scope="scope">
            <div v-if="scope.row.tuan_users && scope.row.tuan_users.length === 1">
              <div v-if="scope.row.tuan_users[0]">
                <span :style="scope.row.tuan_users[0].is_master > 0 ? 'font-weight: bold' : 'font-weight: normal'">
                  {{scope.row.tuan_users[0].name}}（{{scope.row.tuan_users[0].phone}}）
                </span>
              </div>
              <div v-else>--</div>
            </div>
            <div v-if="scope.row.tuan_users && scope.row.tuan_users.length > 1">
              <div v-for="(item, index) in scope.row.tuan_users" :key="index">
                <div v-if="item">
                  <span :style="item.is_master > 0 ? 'font-weight: bold' : 'font-weight: normal'">
                    {{item.name}}（{{item.phone}}）
                  </span>
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div v-if="!scope.row.tuan_users || scope.row.tuan_users.length === 0">暂无拼团会员</div>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="开团时间"></el-table-column>
        <el-table-column prop="success_at" label="完成时间"></el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 15px"
        @current-change="handleTuanListCurrentChange"
        :current-page="tuanListCurrentPage"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :hide-on-single-page="tuanListCurrentPage < 2"
        :total="tuanListTotal"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
// import VueUeditorWrap from "vue-ueditor-wrap";
import IQuillEditor from '../../components/home/i-quill-editor.vue';

export default {
  // components: { VueUeditorWrap },
  components: { IQuillEditor },
  data() {
    return {
      content: '',
      config: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: "http://35.201.165.105:8000/controller.php",
        // serverUrl: "https://testeas.xiaohuadou.cn/wholesale/api/app/meiye/upload/project",
        // serverUrl: "https://testeas.xiaohuadou.cn",
        // imageUrl: "https://testeas.xiaohuadou.cn",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的商品，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "./UEditor/",
        zIndex: 3500,
      },
      type: 0,
      form: {
        name: "",
        begin_time: "",
        end_time: "",
        day: "",
        time: "",
        minute: "",
        expire: "",
        group_type: "",
        goods_id: "",
        stock: "999",
        people_count: 2,
        retail_price: 999,
        group_price: 999,
        goods_info: [],
        status: "1",
        detail: '',
        is_coutuan: false,
      },
      input3: "",
      select: "",
      tableData: [],
      currentPage4: 1,
      dialogVisible: false,
      options: [],
      dialogVisible2: false,
      product: 0,
      dialogTableVisible: false,
      product_data: [],
      product_type: [],
      tuanListData: [],
      page: 1,
      total: 1,
      totals: 1,
      pages: 1,
      at_id: '',
      tuanListCurrentPage: 1,
      tuanListTotal: 1,
      tuanListPage: 1,
    };
  },
  methods: {
    /**
     * 富文本内容改变
     */
    handleTextChange(value) {
      // console.log('textChange', value);
      this.form.detail = value ? value : '<p></p>';
    },
    /**
     * 点击成团数，弹出成团详情列表
     */
    handleShowGroup(at_id) {
      if (this.at_id != at_id) {
        this.tuanListPage = 1;
      }
      this.$axios({
        href: "/api/app/meiye/wechat_order/tuan_list",
        data: {
          at_id,
          page: this.tuanListPage
        },
      }).then((res) => {
        console.log('showGroupRes', res);
        if (res.data.successful === 1) {
          this.at_id = at_id;
          this.dialogTableVisible = true;
          this.tuanListData = res.data.data.items;
          this.tuanListTotal = res.data.data.total;
          this.tuanListCurrentPage = res.data.data.currentPage;
        }
      });
    },
    handleTuanListCurrentChange(val) {
      this.tuanListPage = val;
      this.handleShowGroup(this.at_id);
    },
    handleCommand(command) {
      console.log(command);
      this.type = 0;
      this.form = {
        name: "",
        begin_time: "",
        end_time: "",
        day: "",
        time: "",
        minute: "",
        expire: "",
        group_type: command,
        goods_id: "",
        stock: "999",
        people_count: 2,
        retail_price: 999,
        group_price: 999,
        goods_info: [],
        status: "1",
        is_coutuan: false,
      };
      this.dialogVisible = true;
    },
    handleCommand1(command) {
      console.log(command);
      this.product = command - 0;
      this.getproducttype(this.product);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pages = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
      console.log(`当前页: ${val}`);
    },
    handleSizeChange1(val) {
      this.page = val;
      this.getproducttype(this.product);
    },
    handleCurrentChange1(val) {
      this.page = val;
      this.getproducttype(this.product);
    },
    getproducttype(index) {
      // if (index == 1) {
      //   this.$message.error("商品功能升级中,稍后再试...");
      //   return false;
      // }
      // console.log(index)

      let arr = [
        {
          data: "/api/app/meiye/producttype/store",
          type: "/api/app/meiye/product/store",
        },
        {
          data: "/api/app/meiye/projecttype/store",
          type: "/api/app/meiye/project/store",
        },
        {
          data: "/api/app/meiye/projecttype/store",
          type: "/api/app/meiye/project_card/store",
        },
      ];
      let _this = this;
      this.$axios({
        href: arr[index - 1].data,
      }).then((res) => {
        console.log(res.data);

        let len = res.data.length;

        for (let i = 0; i < len; i++) {
          res.data[i].list = [];
        }

        _this.product_type = res.data;
      });
      this.$axios({
        href: arr[index - 1].type,
        data: { page: this.page },
      }).then((res) => {
        console.log(res);

        if (index == 1) {
          let len = res.data.items.length;
          let len1 = _this.product_type.length;
          this.total = res.data.total || len;

          for (let i = 0; i < len1; i++) {
            for (let l = 0; l < len; l++) {
              if (res.data.items[l].category_id == _this.product_type[i].id) {
                _this.product_type[i].list.push(res.data.items[l]);
              }
            }
          }
          _this.product_data = res.data.items;
          this.dialogVisible2 = true;
          return false;
        }
        if (index == 2) {
          let len = res.data.length;
          let len1 = _this.product_type.length;
          this.total = res.data.total || len;

          for (let i = 0; i < len1; i++) {
            for (let l = 0; l < len; l++) {
              if (res.data[l].category_id == _this.product_type[i].id) {
                _this.product_type[i].list.push(res.data[l]);
              }
            }
          }
          _this.product_data = res.data;
          this.dialogVisible2 = true;
          return false;
        }
        if (index == 3) {
          let len = res.data.data.items.length;
          let len1 = _this.product_type.length;
          this.total = res.data.data.total || len;

          for (let i = 0; i < len1; i++) {
            for (let l = 0; l < len; l++) {
              if (
                res.data.data.items[l].project.category_id ==
                _this.product_type[i].id
              ) {
                _this.product_type[i].list.push(res.data.data.items[l]);
              }
            }
          }
          _this.product_data = res.data.data.items;
          this.dialogVisible2 = true;
          return false;
        }
      });
    },

    getList() {
      this.$axios({
        href: "/api/app/meiye/pintuan/store",
        data: { page: this.pages },
      }).then((res) => {
        let d = res.data.data;
        this.totals = d.total;
        this.tableData = d.items;
        console.log(res);
      });
    },
    save_pintuan() {
      let d = JSON.parse(JSON.stringify(this.form));

      if (!d.name) {
        this.$message({
          message: "请输入活动名称",
          type: "warning",
        });
        return false;
      }
      if (!d.begin_time || !d.end_time) {
        this.$message({
          message: "请选择开始时间或结束时间",
          type: "warning",
        });
        return false;
      }

      if (new Date(d.begin_time).getTime() > new Date(d.end_time).getTime()) {
        this.$message({
          message: "开始时间不能大于结束时间",
          type: "warning",
        });
        return false;
      }

      if (!d.buy_begin_time || !d.buy_end_time) {
        this.$message({
          message: "请选择购买开始时间或购买结束时间",
          type: "warning",
        });
        return false;
      }
      if (
        new Date(d.buy_begin_time).getTime() >
        new Date(d.buy_end_time).getTime()
      ) {
        this.$message({
          message: "开始购买时间不能大于购买结束时间",
          type: "warning",
        });
        return false;
      }

      if (!d.minute && !d.day && !d.time) {
        this.$message({
          message: "请输入团有效期",
          type: "warning",
        });
        return false;
      }

      if (!d.people_count) {
        this.$message({
          message: "请输入拼团人数",
          type: "warning",
        });
        return false;
      }

      if (!d.retail_price) {
        this.$message({
          message: "请输入原价",
          type: "warning",
        });
        return false;
      }

      if (!d.group_price) {
        this.$message({
          message: "请输入拼团价",
          type: "warning",
        });
        return false;
      }

      if (!d.goods_info.length) {
        this.$message({
          message: "请选择商品",
          type: "warning",
        });
        return false;
      }
      d.expire = 0;
      d.expire += (d.day - 0) * 24 * 60;
      d.expire += (d.time - 0) * 60;
      d.expire += d.minute - 0;

      // console.log((d.day - 0) * 24 * 60,(d.time - 0) * 60,d.minute - 0)

      d.is_coutuan = d.is_coutuan ? 1 : 0;

      console.log(d);

      let href = this.type
        ? "/api/app/meiye/pintuan/edit"
        : "/api/app/meiye/pintuan/add";

      this.$axios({
        href: href,
        data: d,
      }).then(() => {
        this.$message({
          message: this.type ? "修改拼团成功" : "添加拼团成功",
          type: "success",
        });
        this.form.detail = '';
        this.getList();
        this.dialogVisible = false;
      });

      //
    },
    select_goods(scope) {
      console.log(scope, this.product);
      this.form.goods_type = this.product;
      this.form.goods_id = scope.id;
      this.form.goods_info = [scope];
      this.form.retail_price = scope.price;
      // this.dialogVisible1 = true;
      this.dialogVisible2 = false;
    },
    handleEdit(obj) {
      this.type = 1;
      let d = JSON.parse(JSON.stringify(obj.row));
      let t = d.expire - 0;
      d.day = parseInt(t / 60 / 24);
      d.time = parseInt((t - d.day * 24 * 60) / 60);
      d.minute = t - d.day * 24 * 60 - d.time * 60;

      d.goods_info = [d.goods_info];
      d.is_coutuan = d.is_coutuan - 0 ? true : false;
      d.group_type = d.group_type ? "1" : "0";
      d.status = d.status > 0 ? "1" : "-1";

      this.form = d;
      this.dialogVisible = true;
    },
    handleDelete(obj) {
      console.log(obj.row.id);

      this.$confirm("此操作将永久删除该拼团活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/pintuan/del",
            data: { id: obj.row.id },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          });
        })
        .catch(() => {});
    },
  },

  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.group {
  width: 100%;
  height: auto;
  &-head {
    width: 100%;
    height: 100px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    &-l {
      width: 40%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-r {
      width: 60%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &-box {
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
  }
}
.alerts {
  width: 100%;
  height: 100%;
  &-list,
  &-lists {
    width: 100%;
    min-height: 60px;
    margin: 10px auto;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-item {
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>

<style moudle>
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}
@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
</style>
